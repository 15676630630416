import React from "react";
import { Link } from "react-router-dom";

import Home from "../content/Home";
import Calculator from "../content/Calculator";
import Contact from "../content/Contact";
import About from "../content/About";
import Faq from "../content/Faq";
import ProductPage from "../content/ProductPage";
import Products from "../content/Products";
import MiscContent from "../content/MiscContent";
import NotFound from "../content/NotFound";

import { LoadProducts, LoadProductPage } from "./LoadData";
import SideBorderedContent from "../frames/SideBorderedContent";

const AlreadySubbed = () => <MiscContent title="hm." subtitle="you're already subscribed (・・ )." />;

const Subbed = () => <MiscContent title="yay!" subtitle="talk to you soon, new subscriber (o˘◡˘o)." />;

const Unsubbed = () => <MiscContent title="bye." subtitle="successfully unsubscribed. see you (＠´ー`)ﾉﾞ" />;

const Sendy = () => <SideBorderedContent content={<a href="/sendy">Help me!</a>} />;

const Routes = [
	{
		path: "/",
		exact: true,
		component: Home,
	},
	{
		path: "/calculator",
		component: Calculator,
	},
	{
		path: "/faq",
		component: Faq,
	},
	{
		path: "/about",
		component: About,
	},
	{
		path: "/contact",
		component: Contact,
	},
	{
		path: "/products/:id",
		component: Products,
		loadData: LoadProducts,
	},
	{
		path: "/product/gid://shopify/Product/:id",
		component: ProductPage,
		loadData: LoadProductPage,
	},
	{
		path: "/already-subbed",
		component: AlreadySubbed,
	},
	{
		path: "/subbed",
		component: Subbed,
	},
	{
		path: "/unsubbed",
		component: Unsubbed,
	},
	{
		path: "/sendy1",
		component: Sendy,
	},
	{
		component: NotFound,
	},
];

export default Routes;
