import React, { Component } from 'react';
import SideBorderedContent from '../frames/SideBorderedContent'
import SquiggleHeader from '../frames/SquiggleHeader'
import Decor from './Decor'
import Helmet from "react-helmet";


class About extends Component {
  render() {
    let { isM } = this.props;

    let content = <div className="stretch pink-bg rel flex-row flex-wrap">
      <Helmet
        title="about."
      />
      <Decor options={0} isM={isM} />
      <SquiggleHeader
        title='about.'
        filter='invert(0.5) sepia(100%) hue-rotate(310deg)'
        color='white'
      />
      <div className={"restrict-content book-12 white" + (isM === true ? ' block-horz' : '')} style={{
        lineHeight: '200%'
      }}>
        softy is an online merch store inspired by dark and cute aesthetics.
        <br />
        created by angela he.
        <br />
        <br />
        <b className="bold-12">timeline</b>
        <br />
        <i>april 2019</i> - open shop
        <br />
        <i>summer 2019</i> - release clothing line
        </div>
    </div>

    return (
      <SideBorderedContent
        isM={this.props.isM}
        content={content}
      />
    );
  }
}

export default About;
