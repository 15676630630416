import React, { Component } from "react";
import Zoom from "../frames/Zoom";
import VariantSelector from "../components/VariantSelector";
import NotFound from "./NotFound";
import Helmet from "react-helmet";
import { LoadProductPage } from "../static/LoadData";
// import { ID_PREFIX } from "../static/StaticData";
import { TryGetData, DeleteWindowData } from "../functions/TryGetData";

class ProductPage extends Component {
	constructor(props) {
		super(props);

		let arr = {
			selectedVariant: null,
			selectedVariantImage: null,
			selectedOptions: null,
			selectedVariantQuantity: 1,
			error: false,
			decorColor: null,
			timer: null,
			bgColor: "#FFFFFF",
		};

		if (props.staticContext && props.staticContext.data) {
			this.state = {
				arr,
				product: props.staticContext.data.responses[0],
			};
		} else {
			this.state = {
				arr,
				product: null,
			};
		}
	}

	componentDidMount = () => {
		this.setState({
			timer: TryGetData(this.checkProduct),
		});
	};

	componentWillUnmount = () => {
		if (this.state.timer != null) {
			clearTimeout(this.state.timer);
		}
	};

	getId = (id) => "gid://shopify/Product/" + id;

	checkProduct = () => {
		if (this.state.product == null) {
			if (window.__ROUTE_DATA__ && window.__ROUTE_DATA__.responses[0]) {
				this.loadProduct(window.__ROUTE_DATA__.responses[0]);
			} else {
				// console.log(this.props.match);

				LoadProductPage(this.props.client, this.getId(this.props.match.params.id))
					.then((data) => {
						this.loadProduct(data);
					})
					.catch((error) => console.log(JSON.stringify(error)));
			}
		}
	};

	loadProduct = (product) => {
		if (product == null) {
			this.setState({ error: true });
			return;
		}

		this.getTags(product.handle);

		let defaultOptionValues = {};
		product.options.forEach((selector) => {
			defaultOptionValues[selector.name] = selector.values[0].value;
		});

		this.setState({
			selectedOptions: defaultOptionValues,
			product: product,
			selectedVariant: product.variants.find((v) => v.available),
			timer: null,
		});

		DeleteWindowData();
	};

	getTags = (handle) => {
		const query = this.props.client.graphQLClient.query((root) => {
			root.add("productByHandle", { args: { handle: handle } }, (product) => {
				product.add("tags");
			});
		});
		this.props.client.graphQLClient.send(query).then(({ model, data }) => {
			// if (model == null) return;

			// console.log(model);
			// console.log(data);
			let tags = model.productByHandle.tags;

			if (tags != null && tags.length > 1) {
				let lastTag = tags[tags.length - 1],
					secondLastTag = tags[tags.length - 2];
				var isOk = /^#[0-9A-F]{6}$/i.test(lastTag) && /^#[0-9A-F]{6}$/i.test(secondLastTag);
				if (isOk === true) {
					this.setBgColor(lastTag);
					this.setState({ decorColor: secondLastTag });
				}
			}
		});
	};

	findImage = (images, variantId) => {
		const primary = images[0];

		const image = images.filter(function (image) {
			return image.variant_ids.includes(variantId);
		})[0];

		return (image || primary).src;
	};

	setBgColor = (color) => {
		this.setState({ bgColor: color });
	};

	onImageChange = (image) => {
		this.setState({ selectedVariantImage: image });
	};

	handleOptionChange = (event) => {
		const target = event.target;
		let selectedOptions = this.state.selectedOptions;
		selectedOptions[target.name] = target.value;

		const selectedVariant = this.props.client.product.helpers.variantForOptions(this.state.product, selectedOptions);

		this.setSelected(selectedVariant);
	};

	handleQuantityChange = (event) => {
		this.setState({
			selectedVariantQuantity: event.target.value,
		});
	};

	isSelected = (val) => {
		let { selectedVariant } = this.state;
		return val === selectedVariant.title;
	};

	setSelected(selectedVariant) {
		this.setState({
			selectedVariant: selectedVariant,
		});
	}

	render() {
		let { error, product, selectedVariant } = this.state,
			{ isM } = this.props;

		if (error === true) return <NotFound />;
		if (product == null && selectedVariant == null) return null;

		let variantImage = this.state.selectedVariantImage || product.images[0];
		let variantQuantity = this.state.selectedVariantQuantity || 1;
		let variantSelectors = null;

		// console.log("product", product);
		// console.log("selectevariant", selectedVariant);

		if (product.availableForSale && product.options.length > 0) {
			variantSelectors = product.options.map((options) => {
				if (options.values.length > 1)
					return <VariantSelector handleOptionChange={this.handleOptionChange} options={options} selected={this.isSelected} />;
			});
		}

		let imageButtons = null;
		if (product.images.length > 1) {
			imageButtons = product.images.map((image, index) => {
				if (image == variantImage) return;
				return (
					<button className="small-images" key={index} onClick={() => this.onImageChange(image)}>
						<img src={image.src} alt={`${product.title} product shot`} />
					</button>
				);
			});
		}

		let rect, circle;
		if (this.state.decorColor != null) {
			rect = <div className="abs rect-dec2" style={{ background: this.state.decorColor }} />;
			circle = (
				<div
					className="circle-dec3"
					style={{
						border: "8px " + this.state.decorColor + " solid",
					}}
				/>
			);
		}

		let button;
		if (product.availableForSale === false || (selectedVariant != null && selectedVariant.available === false)) {
			button = (
				<React.Fragment>
					<button className="button med-18 smblock-top" disabled={true}>
						sold out
					</button>
					<div className="bold-14 flex-row" style={{ marginTop: "12px" }}>
						<span className="jam jam-alert med-20 eensy-pad" />
						<i>join the email list to be notified of restocks</i>
					</div>
				</React.Fragment>
			);
		} else {
			button = (
				<button className="button med-18 smblock-top" onClick={() => this.props.addVariantToCart(selectedVariant.id, variantQuantity)}>
					add to cart
				</button>
			);
		}

		let image = <Zoom isM={isM} src={variantImage.src} title={product.title} />;

		let price = selectedVariant != null ? selectedVariant.price.amount : product.variants[0].price.amount;
		let maxQuant = selectedVariant != null ? selectedVariant.quantityAvailable : 0;
		// console.log("maxQuant" + maxQuant);

		return (
			<div className="stretch-width stretch flex-row flex-wrap rel scroll align-start" style={{ background: this.state.bgColor }}>
				<Helmet
					title={product.title + "."}
					meta={[
						{ property: "og:type", content: "product" },
						{ property: "og:title", content: product.title },
						{
							property: "product:price:amount",
							content: price,
						},
						{ property: "product:price:currency", content: "USD" },
						{ property: "og:site_name", content: "softy" },
						{
							property: "og:description",
							content: product.description,
						},
					]}
				/>

				<div className="stretch product-page-images">
					<div className="relative">
						{rect}
						{image}
					</div>
					<div className="flex-row smblock-vert justify-end">{imageButtons}</div>
				</div>

				<div className="stretch flex-vertical align-start vw-padding product-description">
					<h2 className="relative product-title">
						{circle}
						<span className="relative">{product.title}</span>
					</h2>
					<h4>${price}</h4>
					{variantSelectors}
					<label className="product-option tinypad-top flexRow justifyStart">
						<p className="option_title">qty</p>
						<input min="1" max={maxQuant} type="number" defaultValue={variantQuantity} onChange={this.handleQuantityChange}></input>
					</label>
					{button}
					<div className="hr" />
					<div
						className="darkgrey"
						dangerouslySetInnerHTML={{
							__html: product.descriptionHtml,
						}}
					></div>
				</div>
			</div>
		);
	}
}

export default ProductPage;
