import React, { Component } from "react";
import SideBorderedContent from "../frames/SideBorderedContent";
import SquiggleHeader from "../frames/SquiggleHeader";
import Decor from "./Decor";
import StaticData from "../static/StaticData";
import Helmet from "react-helmet";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FAQkey: StaticData.FAQhome,
    };
  }

  render() {
    let { FAQkey } = this.state;

    let el;

    if (FAQkey == StaticData.FAQhome) {
      el = [];
      for (let key in StaticData.FAQ) {
        el.push(
          <button
            className="bold-14 tiny-block white"
            key={key}
            onClick={() => this.setState({ FAQkey: key })}
          >
            {key}
          </button>
        );
      }
      el = (
        <div className="restrict-content flex-vertical align-start">{el}</div>
      );
    } else {
      let content = StaticData.FAQ[FAQkey].map((item, index) => {
        return (
          <div
            className="flex-vertical smblock-vert align-start"
            key={"key" + index}
          >
            <span className="bold-14 tinyblock-vert">{item.title}</span>
            <span className="book-12 prewrap">{item.answer}</span>
            {item.component ? item.component : null}
            {item.footer ? (
              <span className="book-12 prewrap">{item.footer}</span>
            ) : null}
          </div>
        );
      });
      el = (
        <div className="restrict-content white margin-bottom block-horz">
          <header className="flex-row justify-start">
            <button
              onClick={() => this.setState({ FAQkey: StaticData.FAQhome })}
            >
              <span className="white jam jam-arrow-left book-20"></span>
            </button>
            <span className="smblock-vert bold-18">
              <u>{FAQkey}</u>{" "}
            </span>
          </header>

          {content}
        </div>
      );
    }

    let content = (
      <div className="stretch pink-bg rel flex-vertical">
        <Helmet title="faq." />
        <Decor options={0} isM={this.props.isM} />
        <SquiggleHeader
          title="faq."
          color="#828282"
          filter="invert(0.68)"
          className="block-margin-bottom"
        />
        {el}
      </div>
    );

    return <SideBorderedContent isM={this.props.isM} content={content} />;
  }
}

export default Faq;
