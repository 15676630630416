import React, { Component } from 'react';
import menu from '../graphics/menu.svg';
import Decor from '../content/Decor'
import StaticData from '../static/StaticData'
/*
 */
class Menu extends Component {
  constructor(props) {
    super(props);
  }

  setDropdown = (bool) => {
    this.props.setDropdown(bool)
    // ("try");

    if (typeof window !== 'undefined')
      if (this.props.isM === false && typeof window !== 'undefined') {
        if (bool == false) {
          document.removeEventListener('mousedown', this.handleClickOutside, false);
        } else {
          document.addEventListener('mousedown', this.handleClickOutside, false);
        }
      }
  }

  componentWillUnmount = () => {
    if (this.props.isM === false && typeof window !== 'undefined') {
      document.removeEventListener('mousedown', this.handleClickOutside, false);
    }
  }

  handleClickOutside = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.setDropdown(false);
  }

  render() {

    let { exitState, isM, allLink, shopLinks, footerLinks } = this.props,
      shop;

    if (this.props.exitState !== 2) {
      if (isM) {
        shop =
          <div className={"flex-vertical shop open-dropdown align-start" + (exitState === 1 ? " exit" : "")}
          >
            <Decor options={3} />

            <button onClick={() => this.setDropdown(false)}
              className="shop flex-row med-22">
              <span className="jam jam-arrow-left" />
            </button>

            <div className="stretch" style={{ paddingTop: '12vh' }} onClick={() => this.setDropdown(false)}>
              {/* {allLink} */}
              {shopLinks}
            </div>
            <div onClick={() => this.setDropdown(false)}>
              {footerLinks}
            </div>
            <div className="flex-row relative stretch-width space-between block-pad">
              <a className="tiny-block" href={StaticData.instaURL}>
                <span className="jam jam-instagram"></span>
              </a>
              <a className="tiny-block" href={StaticData.facebookURL}>
                <span className="jam jam-facebook"></span>
              </a>
              <a className="tiny-block" href={StaticData.twitterURL}>
                <span className="jam jam-twitter"></span>
              </a>
              <a className="tiny-block" href={StaticData.tumblrURL}>
                <span className="jam jam-tumblr"></span>
              </a>
            </div>
          </div>;
      } else {
        shop =
          <div className={"flex-vertical shop open-dropdown align-start" + (exitState === 1 ? " exit" : "")}
            ref={node => this.node = node}
            onMouseLeave={() => this.setDropdown(false)}
          >
            {allLink}
            {shopLinks}
          </div>;
      }

    } else {
      if (isM) {
        shop =
          <button onClick={() => this.setDropdown(true)}
            className="shop flex-row">
            <img src={menu} />
            <div style={{ display: 'none' }}>
              {allLink}
              {shopLinks}
            </div>
          </button>
      } else {
        shop =
          <button onClick={() => this.setDropdown(true)}
            onMouseEnter={() => this.setDropdown(true)}
            className="shop block-horz smblock-vert flex-row">
            <span className="bold-18">shop</span>
            <span className="bold-18 tiny-block jam jam-chevron-down"></span>
            <div style={{ display: 'none' }}>
              {allLink}
              {shopLinks}
            </div>
          </button>
      }
    }

    return (
      shop
    );
  }
}

export default Menu;
