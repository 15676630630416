import React, { Component } from "react";
import LineItem from "./LineItem";
import SquiggleHeader from "../frames/SquiggleHeader";

class Cart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isNotesOpen: false,
			notes: "",
		};
	}

	openCheckout = () => {
		if (this.state.notes !== "") {
			this.props.updateCartAttributes({ note: this.state.notes }).then(() => {
				window.open(this.props.checkout.webUrl);
			});
		} else {
			window.open(this.props.checkout.webUrl);
		}
	};

	render() {
		if (this.props.exitState == 2) return null;

		let { isM } = this.props;

		let cartClass, closeClass;
		if (isM === true) {
			cartClass = "cart flex-vertical smblock-pad";
			closeClass = "topright-close tiny-block";
		} else {
			cartClass = "cart flex-vertical block-pad";
			closeClass = "topright-close small-block";
		}

		if (this.props.checkout.lineItems.length === 0) {
			return (
				<div className={"cart-container abs" + (this.props.exitState === 1 ? " exit" : "")}>
					<div className="abs fade-in"></div>
					<div className={cartClass}>
						<button onClick={this.props.handleCartClose} className={closeClass}>
							<span className="jam jam-close" />
						</button>

						<SquiggleHeader title="cart." filter="invert(0.61) sepia(100%) hue-rotate(300deg)" />
						<p className="darkgrey">your cart is empty.</p>
					</div>
				</div>
			);
		}

		let line_items = this.props.checkout.lineItems.map((line_item) => {
			// console.log(line_item);
			return (
				<LineItem
					isM={isM}
					updateQuantityInCart={this.props.updateQuantityInCart}
					removeLineItemInCart={this.props.removeLineItemInCart}
					key={line_item.id.toString()}
					line_item={line_item}
				/>
			);
		});

		let notes;
		if (this.state.isNotesOpen === true) {
			notes = (
				<textarea
					placeholder="special instructions for delivery, etc"
					rows={isM === true ? 2 : 4}
					className="stretch-width smol-margin-top"
					onChange={(e) => this.setState({ notes: e.target.value })}
				></textarea>
			);
		} else {
			notes = (
				<button className="darkgrey bold-14 smol-margin-top flex-row" onClick={() => this.setState({ isNotesOpen: true })}>
					add order notes <span className="jam jam-plus bold-18"></span>
				</button>
			);
		}
		// console.log(this.props.checkout);

		return (
			<div className={"cart-container abs" + (this.props.exitState === 1 ? " exit" : "")}>
				<div className="abs fade-in"></div>
				<div className={cartClass}>
					<button onClick={this.props.handleCartClose} className={closeClass}>
						<span className="jam jam-close" />
					</button>

					<SquiggleHeader title="cart." className="cart-header" filter="invert(0.61) sepia(100%) hue-rotate(300deg)" />

					<ul className="cart-line-items">{line_items}</ul>

					<footer className="cart-footer flex-vertical align-stretch">
						{notes}
						<div className="small-block flex-vertical">
							<div className="darkgrey book-16">subtotal</div>
							<span className="med-32">${this.props.checkout.subtotalPrice.amount}</span>
						</div>
						<button className="med-18 button" onClick={this.openCheckout}>
							checkout
						</button>
					</footer>
				</div>
			</div>
		);
	}
}

export default Cart;
