import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Client from 'shopify-buy/index.unoptimized.umd';
import { BrowserRouter } from "react-router-dom";

const client = Client.buildClient({
    domain: 'zephyo.myshopify.com',
    storefrontAccessToken: '0f43ae507b62595a6e96522dc61804f2',
});

ReactDOM.hydrate(
    <BrowserRouter>
        <App client={client} />
    </BrowserRouter>
    ,
    document.getElementById('root')
);
