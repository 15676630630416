import React, { Component } from 'react';
import StaticData from '../static/StaticData'

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ''
    }
  }

  setEvent = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {
    let classN = '', 
    footerLinks = null, 
    labelClass,
    emailListClass='bold-14',
    submitListClass,
    formClass,
    {isM} = this.props
    if (!isM) {
      classN = ' space-between';
      footerLinks = this.props.footerLinks
      emailListClass+=" small-block"
      submitListClass="block-horz"
    }else{
      submitListClass="tinyblock-vert button"
      formClass='flex-vertical align-stretch'
      labelClass="tinyblock-vert"
    }
    return (
      <footer className={"topbar flex-row" + classN}>
        {footerLinks}

        <form
        className={formClass}
          onSubmit={() => localStorage.setItem(StaticData.getEmailPopupKey(), true)}
          action="https://softy-shop.com/sendy/subscribe" method="POST" acceptCharset="utf-8">

          <label htmlFor="email" className={labelClass}>
            <span className={emailListClass}>email list</span>
          </label>
          <input type="email" name="email" id="email" placeholder='your email' />

          <input type="hidden" name="hp" id="hp" />
          <input type="hidden" name="list" value="VgS21n892B1IuYybZJnJysXw" />
          <input type="hidden" name="subform" value="yes" />

          <input className={submitListClass} type="submit" value="join" />
        </form>
      </footer>
    )

  }
}

export default Footer;
