import React from "react";
import womens from "../graphics/womens_sizing.png";
import unisex from "../graphics/unisex_sizing.png";

class StaticData extends React.Component {
	static pinURL = "pins";
	static stickerURL = "stickers";
	static apparelURL = "apparel";
	static allURL = "all";

	// static ID_PREFIX = "gid://shopify/Product/";

	static FAQ = {};
	static FAQhome = "home";
	static FAQproducts = "products";
	static FAQorders = "orders";
	static FAQship = "shipping & delivery";
	static FAQreturn = "return & exchange";
	static FAQsiz = "sizing";
	static FAQwash = "wash care";

	static facebookURL = "https://www.facebook.com/softy.shops/";
	static instaURL = "https://www.instagram.com/softyshop_/";
	static twitterURL = "https://twitter.com/softyshop";
	static tumblrURL = "https://zephyo.tumblr.com";

	//to find id, Appjs.printIds()
	static getStickerId() {
		return "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzgwMzUxODg3NDM1";
	}

	static getPinId() {
		return "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzgwMjYwNzU5NjI3";
	}

	static getApparelId() {
		return "Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzE0MTM2MjM5NzI1OQ==";
	}

	static getCheckoutIdKey() {
		return "checkoutId";
	}

	static getEmailPopupKey() {
		return "dontShowEmailPopup";
	}
}

StaticData.FAQ[StaticData.FAQproducts] = [
	{
		title: "The item I want is out of stock. Will it be restocked?",
		answer: "Likely not. But if an item does get restocked, I'll notify the email list and social media.",
	},
	{
		title: "How do I lock and unlock a deluxe clutch?",
		answer:
			"Although deluxe clutches are less common, they're more secure than butterfly clasps or rubber clutches." +
			"\nLock:" +
			"\n\tSlide the clutch onto the pin post." +
			"\nUnlock:" +
			"\n\tPull the two parts of the clutch apart: The clutch is made of a cylinder on the bottom and flat disc on top. Hold the cylinder against the pin while pulling the flat disc away from the pin.",
	},
];

StaticData.FAQ[StaticData.FAQorders] = [
	{
		title: "Can I cancel my order?",
		answer:
			'You can cancel your order within 24 hours of placing the order if it hasn\'t been shipped yet. To cancel, please email info@softy-shop.com with the subject line "cancel order" and the order #.',
	},
	{
		title: "Can I change my order?",
		answer:
			"If your order has shipped, I unfortunately can't change it. Else, please email info@softy-shop.com with what exactly you'd like to change.",
	},
	{
		title: "My order is incorrect / damaged / missing. What do I do?",
		answer:
			"Incorrect order:" +
			'\n\tEmail info@softy-shop.com with the subject line "incorrect item" within 14 days of delivery and I will take care of it with no cost to you.' +
			"\nDamaged order:" +
			"\n\tDamaged orders can be returned for a refund." +
			"\nMissing order:" +
			"\n\tIf you chose shipping with tracking and you do not receive your package within 2 months, please email info@softy-shop.com and I will replace your missing items if you pay shipping. If your shipping did not include tracking, then we cannot take responsibility for your package.",
	},
];

StaticData.FAQ[StaticData.FAQship] = [
	{
		title: "Do you ship internationally?",
		answer: "Yes, items ship worldwide from the US West Coast.",
	},
	{
		title: "What are the different shipping methods?",
		answer:
			"I offer USPS and DHL Express shipping. Some clarification:" +
			"\nUSPS First Class Mail doesn't have tracking." +
			"\nUSPS First Class Package has tracking." +
			"\nUSPS Priority Mail has tracking and is faster than First Class.",
	},
	{
		title: "What is your processing time?",
		answer:
			'I ship orders within 7 days under normal circumstances. If you need to rush your order, email info@softy-shop.com with the subject line "expedite order" and your order # and I\'ll see what I can do.',
	},
	{
		title: "How do I know that my order has been shipped?",
		answer:
			"When your order is sent, you will receive a notification. If you chose a shipping method with tracking, you will also receive your tracking number.",
	},
	{
		title: "How will my order be shipped?",
		answer:
			"All items will be delivered in a poly bubble mailer. Every order comes with a custom sticker. If you have special shipping instructions, such as writing on the sticker, please describe it in your order notes and I will do it if possible.",
	},
];

StaticData.FAQ[StaticData.FAQreturn] = [
	{
		title: "What is your return policy?",
		answer:
			"You may return your order at any time up to 30 days after the delivery date of your order. Note that you will be responsible for the shipping cost of the returned item(s).",
	},
	{
		title: "How do I return items?",
		answer:
			"If you wish to return, email info@softy-shop.com with your name, address, the order #, and which items you'd like to return." +
			"\nShip the items for return with 14 days after emailing." +
			"\nProvide a tracking number if possible." +
			'\nIf a returned item is shipped internationally, mark as "RETURNED GOODS" to avoid Customs charges. I am are not responsible for charges imposed on packages returned by Customs.' +
			"\nIf the product is used or damaged during the return shipment, depreciation in value will be deducted from the refund. It is recommended to send the products well packaged and in their original packaging.",
	},
	{
		title: "When / how will I receive my refund?",
		answer: "Refunds are transferred back to the original form of payment and will be processed within 14 days of receiving the returned item(s).",
	},
	{
		title: "My item arrived, and I changed my mind. Can I exchange it for something else?",
		answer: "No, unfortunately. You will need to return your order (per the return policy) and place an order for a new item.",
	},
];

StaticData.FAQ[StaticData.FAQsiz] = [
	{
		title: "What is the sizing like?",
		answer: "Measurements are in centimeters.",
		component: (
			<div>
				<h4>Measurement guide</h4>
				<b>UNISEX</b>
				<img src={womens} />
				<b>WOMEN</b>
				<img src={unisex} />
			</div>
		),
		footer:
			"\nChest:" +
			"\nMeasure under your arms at the fullest part of your chest. Be sure to go over your shoulder blades." +
			"\n\nNatural Waist:" +
			"\nMeasure around the narrowest part of your waistline.",
	},
];

StaticData.FAQ[StaticData.FAQwash] = [
	{
		title: "What is the sizing?",
		answer:
			"Ribcage tee and long sleeve: 90% polyester, 10% spandex; machine wash cold or hand wash, tumble dry low, do not bleach, do not iron" +
			"\n\nBloom and girls sweater: 52% cotton, 48% polyester; machine wash warm, tumble dry low, only non-chlorine bleach, medium iron" +
			"\n\nEverything else: 100% cotton; machine wash cold, tumble dry low, no bleach, cool iron when needed",
	},
];

export default StaticData;
