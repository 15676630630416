import React, { Component } from 'react';
import Menu from './Menu'
import animated from '../functions/Animated'
import cart from '../graphics/cart.svg';

const AMenu = animated(Menu, 300);

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShopDropdown: false,
    }
  }



  setDropdown = (bool) => this.setState({ isShopDropdown: bool });

  render() {
    let { isShopDropdown } = this.state,
      { home, allLink, shopLinks, footerLinks, isM, isCartOpen, openCart } = this.props;

    let headerStyle = null;

    if (isShopDropdown) {
      if (isM) {
        headerStyle = { filter: 'invert(100%)' }

      }

    }

    let cartContent;
    if (isM) {
      cartContent = <button
        onClick={openCart}
        className="cart-wrapper"
      > <img src={cart} />
        <span className="abs bold-16">{this.props.cartItems}</span>
      </button>
        ;
    } else {
      cartContent = <div className="cart-wrapper">
        <button
          onClick={openCart}
          className="bold-18 block-horz flex-row"
        >cart
      <div className="pink-circle flex-row">
            {this.props.cartItems}
          </div>
        </button>
      </div>;
    }

    let className;
    if (this.props.animateNavBar) {
      className = "topbar";
    }
    else {
      className = "topbar animateTopBar";
    }

    return (
      <header className={className} style={headerStyle}>
        <AMenu
          show={this.state.isShopDropdown}
          isM={isM}
          allLink={allLink}
          shopLinks={shopLinks}
          footerLinks={footerLinks}
          setDropdown={this.setDropdown}
        />
        {home}

        {!isCartOpen &&
          cartContent
        }
      </header>
    );
  }
}

export default NavBar;
