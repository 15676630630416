import React from "react";
import SquiggleHeader from "../frames/SquiggleHeader";
import Decor from "./Decor";
import SideBorderedContent from "../frames/SideBorderedContent";
import StaticData from "../static/StaticData";

/*
  title 
  subtitle
*/
class MiscContent extends React.Component {
  constructor(props) {
    super(props);
  }

  goBack = () => {
    window.history.go(-1);
  };

  render() {
    return (
      <SideBorderedContent
        isM={this.props.isM}
        content={
          <div className="stretch pink-bg rel flex-vertical">
            <Decor options={5} />
            <SquiggleHeader
              title={this.props.title}
              className="no-margin-bottom"
            />
            <span className="smblock-vert">
              {this.props.subtitle}
            </span>

            <div className="flex-row relative space-between block-pad">
              <a className="tiny-block" href={StaticData.instaURL}>
                <span className="jam jam-instagram"></span>
              </a>
              <a className="tiny-block" href={StaticData.facebookURL}>
                <span className="jam jam-facebook"></span>
              </a>
              <a className="tiny-block" href={StaticData.twitterURL}>
                <span className="jam jam-twitter"></span>
              </a>
              <a className="tiny-block" href={StaticData.tumblrURL}>
                <span className="jam jam-tumblr"></span>
              </a>
            </div>

            <button className="flex-row" onClick={this.goBack}>
              <span className="jam jam-arrow-left bold-18 tiny-block"></span>
              <span className="bold-18">go back</span>
            </button>
          </div>
        }
      />
    );
  }
}

export default MiscContent;
