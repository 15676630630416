import React, { Component } from "react";
import StaticData from "../static/StaticData";

// isM
// content
class SideBorderedContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.isM) return this.props.content;

    return (
      <div className="flex-row stretch-width stretch align-stretch">
        <div className="sidebar flex-vertical">
          <span className="vertical-text">
            © 2019 softy all rights reserved
          </span>
        </div>
        {this.props.content}
        <div className="sidebar flex-vertical">
          <a className="tiny-block" href={StaticData.instaURL}>
            <span className="jam jam-instagram"></span>
          </a>
          <a className="tiny-block" href={StaticData.facebookURL}>
            <span className="jam jam-facebook"></span>
          </a>
          <a className="tiny-block" href={StaticData.twitterURL}>
            <span className="jam jam-twitter"></span>
          </a>
          <a className="tiny-block" href={StaticData.tumblrURL}>
            <span className="jam jam-tumblr"></span>
          </a>
        </div>
      </div>
    );
  }
}

export default SideBorderedContent;
