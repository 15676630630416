import React, { Component } from "react";
import SideBorderedContent from "../frames/SideBorderedContent";
import Decor from "./Decor";
import ContactForm from "./ContactForm";
import Helmet from "react-helmet";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      continue: false,
      error: null,
    };
  }

  checkChecked = () => {
    if (this.state.checked === true) {
      this.setState({ continue: true });
    } else {
      this.setState({ error: "Please agree to continue." });
    }
  };

  changeChecked = () => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
      error: null,
    }));
  };

  render() {
    let content;
    if (this.state.continue === false) {
      content = (
        <div className="stretch rel black-bg flex-vertical">
          <Helmet title="contact." />
          <Decor options={3} />
          <div className="flex-row white small-block book-16">
            <button
              className="checkbox small-block flex-row"
              onClick={this.changeChecked}
            >
              {this.state.checked && (
                <span
                  className="jam jam-check book-20"
                  style={{ color: "black" }}
                ></span>
              )}
            </button>
            <span>
              I looked through the faq and didn’t find what I need.
            </span>
          </div>

          <button
            className="button white"
            onClick={this.checkChecked}
          >
            continue
          </button>
          {this.state.error != null && (
            <span className="pink book-12 tiny-block">
              {this.state.error}
            </span>
          )}
        </div>
      );
    } else {
      content = <ContactForm />;
    }
    return (
      <SideBorderedContent isM={this.props.isM} content={content} />
    );
  }
}

export default Contact;
