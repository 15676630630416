import React, { Component } from 'react';
import Loading from '../components/Loading'

class Zoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundPosition: '0% 0%',
      loaded: 0,
      unMounting: false
    }
  }

  componentWillUnmount = () => {
    this.setState({ unMounting: true })
  }

  onLoad = () => {
    this.setState({ loaded: this.state.loaded + 1 });
  }

  handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = (e.pageX - left) / width * 100
    const y = (e.pageY - top) / height * 100
    this.setState({ backgroundPosition: `${x}% ${y}%` })
  }

  render() {
    let style;
    let displayNone = { opacity: '0' };
    let LoadingEl = null;
    let { isM } = this.props;


    if (this.state.loaded < 1) {
      style = displayNone;
      LoadingEl = <Loading />
    }
    let func = (this.state.unMounting) ? null : this.onLoad;

    let className = (isM == false) ? "product-image relative" : "relative";

    return <div
      onMouseMove={(isM == false) ? this.handleMouseMove : null}
      className={className} >
      {isM == false ? <div className="closeup-image" style={{
        backgroundImage: `url(${this.props.src})`,
        backgroundPosition: this.state.backgroundPosition
      }} /> : null }
      <img
        className="main-image"
        src={this.props.src}
        alt={`${this.props.title} product shot`}
        style={style}
        onLoad={func}
      />
      {LoadingEl}
    </div>;
  }
}

export default Zoom;
