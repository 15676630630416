import React from "react";
import { Link } from "react-router-dom";
import SideBorderedContent from "../frames/SideBorderedContent";
import SquiggleHeader from "../frames/SquiggleHeader";
import ProductImage from "./ProductImage";
import Decor from "./Decor";
import StaticData from "../static/StaticData";

const moveForce = 30;
const rotateForce = 20;
const p2Ratio = 0.8;
const p3Ratio = 1.2;

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			p1Style: {},
			p2Style: {},
			p3Style: {},
			timer: setTimeout(this.getProducts, 300),
		};
	}

	getProducts = () => {
		this.setState({
			timer: null,
		});
		if (this.props.isM === false && typeof window !== "undefined") {
			document.addEventListener("mousemove", this.adjustTransform);
		}
	};

	componentWillUnmount = () => {
		if (this.state.timer != null) {
			clearTimeout(this.state.timer);
		} else {
			if (this.props.isM === false && typeof window !== "undefined") document.removeEventListener("mousemove", this.adjustTransform);
		}
	};

	adjustTransform = (e) => {
		var w = window,
			d = document,
			el = d.documentElement,
			g = d.getElementsByTagName("body")[0],
			docX = w.innerWidth || el.clientWidth || g.clientWidth,
			docY = w.innerHeight || el.clientHeight || g.clientHeight;

		var moveX = ((e.pageX - docX / 2) / (docX / 2)) * -moveForce;
		var moveY = ((e.pageY - docY / 2) / (docY / 2)) * -moveForce;

		var rotateY = (e.pageX / docX) * rotateForce * 2 - rotateForce;
		var rotateX = -((e.pageY / docY) * rotateForce * 2 - rotateForce);

		this.setState({
			p1Style: {
				transform: "translate(" + moveX + "px, " + moveY + "px) rotateX(" + rotateX + "deg) rotateY(" + rotateY + "deg)",
			},
			p2Style: {
				transform:
					"translate(" +
					moveX * p2Ratio +
					"px, " +
					moveY * p2Ratio +
					"px) rotateX(" +
					rotateX * p2Ratio +
					"deg) rotateY(" +
					rotateY * p2Ratio +
					"deg)",
			},
			p3Style: {
				transform:
					"translate(" +
					moveX * p3Ratio +
					"px, " +
					moveY * p3Ratio +
					"px) rotateX(" +
					rotateX * p3Ratio +
					"deg) rotateY(" +
					rotateY * p3Ratio +
					"deg)",
			},
		});
	};

	getProductPrev = (id, style) => {
		return (
			<Link to={"/product/" + id} className="tiny-block prev-image flex-row" style={style} key={id}>
				<ProductImage client={this.props.client} id={id} />
			</Link>
		);
	};

	render() {
		let products,
			cTAEl,
			{ isM } = this.props;

		if (this.state.timer == null) {
			let p2 = "gid://shopify/Product/4011717722187",
				p1 = "gid://shopify/Product/4011706646603",
				p3 = "gid://shopify/Product/4011707564107";
			if (isM === true) {
				products = [this.getProductPrev(p1, this.state.p1Style), this.getProductPrev(p2, this.state.p2Style)];
				cTAEl = (
					<Link to={"/products/" + StaticData.allURL} className="button v2 flex-row cta">
						<span className="bold-18 white">shop now</span>
					</Link>
				);
			} else {
				products = [
					this.getProductPrev(p1, this.state.p1Style),
					this.getProductPrev(p2, this.state.p2Style),
					this.getProductPrev(p3, this.state.p3Style),
				];
			}
		}

		let content = (
			<div className="stretch pink-bg rel align-stretch flex-vertical">
				<Decor options={1} />
				<SquiggleHeader className="abs home-header z-index-front" title="softy." subtitle="cute / dark merch." />

				<div className="flex-row stretch block">{products}</div>
				{cTAEl}
			</div>
		);

		return <SideBorderedContent isM={this.props.isM} content={content} />;
	}
}

export default Home;
