import React, { Component } from 'react';

/*
req props:
  show
*/
function animated(WrappedComponent, timeout) {
  // ...and returns another component...
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        exitState: 2,
        timer: null
      };
    }

    /*ANIMATION */

    componentDidUpdate = (prevProps, prevState) => {
      if (prevProps.show != this.props.show) {
        if (this.state.timer != null) {
          clearTimeout(this.state.timer);
          this.setState({ timer: null })
        }
        if (this.props.show === false) {
          this.setState({
            exitState: 1,
            timer: setTimeout(() => {
              this.setState({ exitState: 2, timer: null });
            }, timeout)
          });
        } else {
          this.setState({ exitState: 0 });
        }
      }
    }

    /*ANIMATION END */

    render() {
      return <WrappedComponent exitState={this.state.exitState} {...this.props} />;
    }
  };
}
export default animated;
