import React, { Component } from 'react';
import squiggle from '../graphics/squiggle.svg';

class SquiggleHeader extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let classes = this.props.className == null ? '' : ' ' + this.props.className
    let style = this.props.filter == null ? null : { filter: this.props.filter }
    let fontStyle = this.props.color == null ? null : { color: this.props.color }
    return (
      <div className={"relative flex-vertical small-block margin-bottom squiggle-header" + classes}>
        <h1 style={fontStyle}>{this.props.title}</h1>
        <img src={squiggle} alt='' style={style} />
        
        {this.props.subtitle != null ?
          <p className="book-20">{this.props.subtitle}</p> : null}
      </div>
    );
  }
}

export default SquiggleHeader;
