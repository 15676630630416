import React, { Component } from "react";
import SideBorderedContent from "../frames/SideBorderedContent";
import SquiggleHeader from "../frames/SquiggleHeader";
import NotFound from "./NotFound";
import Decor from "./Decor";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";

import FadeImage from "../frames/FadeImage";

import { LoadProducts } from "../static/LoadData";
// import { ID_PREFIX } from "../static/StaticData";

import { TryGetData, DeleteWindowData } from "../functions/TryGetData";

class Products extends Component {
	constructor(props) {
		super(props);

		let arr = {
			error: false,
			timer: null,
		};

		if (props.staticContext && props.staticContext.data) {
			this.state = {
				arr,
				products: props.staticContext.data.responses[0],
			};
			// console.log("products " + props.staticContext.data.responses[0]);
		} else {
			this.state = {
				arr,
				products: null,
			};
		}
	}

	componentDidMount = () => {
		this.setState({
			timer: TryGetData(this.checkProducts),
		});
	};

	componentWillUnmount = () => {
		if (this.state.timer != null) {
			clearTimeout(this.state.timer);
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.match.params.id != this.props.match.params.id) {
			this.setState({ products: null }, this.checkProducts);
		}
	};

	checkProducts = () => {
		if (this.state.products == null) {
			if (window.__ROUTE_DATA__ && window.__ROUTE_DATA__.responses[0]) {
				this.setProducts(window.__ROUTE_DATA__.responses[0]);
			} else {
				LoadProducts(this.props.client, this.props.match.params.id)
					.then((data) => {
						this.setProducts(data);
					})
					.catch((error) => {
						console.log("error:" + error.message);
						this.setError(true);
					});
			}
		} else {
			DeleteWindowData();
		}
	};

	setProducts = (products) => {
		this.setState({ products: products }, DeleteWindowData);
		this.setState({ timer: null });
	};

	setError = (bool) => this.setState({ error: bool });

	getProductPageLink = (product) => {
		// console.log(product);

		let primaryImage = product.images[0];
		let variant = product.variants[0];

		let image = null;
		let imageStyle = variant.available === false ? { opacity: 0.5 } : {};

		if (product.images.length) {
			if (product.images.length > 1) {
				let secondImage = product.images[1];
				image = <FadeImage imageStyle={imageStyle} primaryImage={primaryImage.src} secondImage={secondImage.src} title={product.title} />;
			} else {
				image = <img className="med-images" src={primaryImage.src} alt={`${product.title} product shot`} style={imageStyle} />;
			}
		}

		if (product.availableForSale === false) {
			image = (
				<div className="relative">
					{image}
					<span className="abs flex-row badge-circle block-pad bold-14">sold out</span>
				</div>
			);
		}

		// var id = product.id.replace(ID_PREFIX, "");

		return (
			<Link
				to={"/product/" + product.id}
				className={(this.props.isM === false ? "tiny-block" : "eensy-pad") + " product" + (variant.available === false ? " sold-out" : "")}
				key={product.id}
			>
				{image}
				<div className="bold-18 smol-margin-top">${variant.price.amount}</div>
				<div className="book-12">{product.title}</div>
			</Link>
		);
	};

	render() {
		if (this.state.error === true) return <NotFound />;

		let { products } = this.state,
			productEl = null;
		// console.log(products);

		if (products != null) {
			productEl = products.map((product) => {
				return this.getProductPageLink(product);
			});
		}
		// return null;

		let title = this.props.match.params.id + ".";

		return (
			<SideBorderedContent
				isM={this.props.isM}
				content={
					<div className="stretch scroll rel">
						<Helmet
							title={title}
							meta={[
								{
									property: "og:description",
									content: "products in the " + this.props.match.params.id + " collection.",
								},
							]}
						/>
						<Decor options={2} />
						<SquiggleHeader title={title} />
						<div className="flex-row flex-wrap">{productEl}</div>
					</div>
				}
			/>
		);
	}
}

export default Products;
