
export const TryGetData = (callback) => {
  if (typeof window === 'undefined') return;

  return setTimeout(callback, 0);
}

export const DeleteWindowData = () => setTimeout(() => {
  let node = document.querySelector('#route_data');
  if (node) {
    node.remove();
    try {
      delete window.__ROUTE_DATA__;

    } catch (e) {
      window["__ROUTE_DATA__"] = undefined;
    }
  }
}, 10);

