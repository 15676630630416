import React, { Component } from 'react';
import Loading from '../components/Loading'

class FadeImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSecondImage: false,
      loaded: 0,
      unMounting: false
    }
  }

  componentWillUnmount = () => {
    this.setState({ unMounting: true })
  }

  onLoad = () => {
    this.setState({ loaded: this.state.loaded + 1 });
  }

  handleMouseEnter = e => {
    this.setState({
      showSecondImage: true
    })
  }

  handleMouseExit = e => {
    this.setState({
      showSecondImage: false
    })
  }

  render() {
    let firstStyle, secondStyle;
    let displayNone = { opacity: '0' };
    let LoadingEl = null;

    if (this.state.loaded < 2) {
      firstStyle = displayNone;
      secondStyle = displayNone;
      LoadingEl = <Loading />
    }
    else {
      if (this.state.showSecondImage == true) {
        firstStyle = displayNone;
      }
      else {
        secondStyle = displayNone;
      }
    }

    let func = (this.state.unMounting) ? null : this.onLoad;

    return <div className="med-images"
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseExit}>
      <img className="abs" src={this.props.primaryImage} style={firstStyle} onLoad={func} />
      <img className="abs" src={this.props.secondImage} style={secondStyle} onLoad={func} />
      {LoadingEl}
    </div>;
  }
}

export default FadeImage;
