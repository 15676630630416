import "./sass/style.scss";

//FONT WILL NOT LOAD IN DEV LOCALHOST - dont worry, will load when put on cPanel server

import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import Helmet from "react-helmet";

import Cart from "./components/Cart";
import NavBar from "./frames/NavBar";
import Footer from "./frames/Footer";
// import Popup from './frames/Popup'

import StaticData from "./static/StaticData";
import Routes from "./static/RouteData";

import animated from "./functions/Animated";
import isMobile from "./functions/isMobile";

// const APopup = animated(Popup, 300);
const ACart = animated(Cart, 300);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCartOpen: false,
      checkout: { lineItems: [] },
      shop: {},
      haveLoaded: false,
      isM: false,
      // showPopup: false,
    };
  }

  componentDidMount = () => {
    this.setState({ isM: isMobile() });
  };

  printIds = () => {
    this.props.client.collection.fetchAllWithProducts().then((collections) => {
      // for (const c of collections) {
      //   console.log(
      //     c.id + "\n" + c.products[0].title + "\n" + c.products.length
      //   );
      // }
    });
  };

  createNewCheckout = () => {
    this.props.client.checkout.create().then((res) => {
      this.setState({
        checkout: res,
      });

      if (typeof window === "undefined") return;

      localStorage.setItem(StaticData.getCheckoutIdKey(), res.id);
    });
  };

  componentWillMount() {
    if (typeof window !== "undefined") {
      let checkoutId = localStorage.getItem(StaticData.getCheckoutIdKey());
      // let dontShowEmailPopup = localStorage.getItem(StaticData.getEmailPopupKey());

      if (checkoutId == null) {
        this.createNewCheckout();
      } else {
        this.props.client.checkout.fetch(checkoutId).then((res) => {
          if (res == null) {
            this.createNewCheckout();
          } else {
            this.setState({
              checkout: res,
            });
          }
        });
      }

      // if (dontShowEmailPopup == null) {
      //   setTimeout(() => {
      //     this.setState({ showPopup: true });
      //   },
      //     5000
      //   );
      // }
    }

    this.props.client.shop.fetchInfo().then((res) => {
      this.setState({
        shop: res,
      });
    });
  }

  getEmailPopup = () => {
    if (typeof window === "undefined") return null;
    return (
      <APopup
        show={this.state.showPopup}
        title="join the email list? (´･ᴗ･ ` )"
        subtitle="be the first to know about sales, new items, and more!"
        content={
          <form
            className="flex-row stretch-width tiny-pad"
            onSubmit={() =>
              localStorage.setItem(StaticData.getEmailPopupKey(), true)
            }
            action="https://softy-shop.com/sendy/subscribe"
            method="POST"
            acceptCharset="utf-8"
          >
            <input
              className="stretch"
              type="email"
              name="email"
              id="email"
              placeholder="your email"
              style={{ marginLeft: "12px" }}
            />

            <input type="hidden" name="hp" id="hp" />
            <input type="hidden" name="list" value="VgS21n892B1IuYybZJnJysXw" />
            <input type="hidden" name="subform" value="yes" />

            <input
              className="button tinyblock-horz"
              type="submit"
              value="join"
            />
          </form>
        }
        storageKey={StaticData.getEmailPopupKey()}
        closePopup={() => this.setState({ showPopup: false })}
      />
    );
  };

  addVariantToCart = (variantId, quantity) => {
    this.setState({
      isCartOpen: true,
    });

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  };

  updateCartAttributes = (input) => {
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .updateAttributes(checkoutId, input)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  };

  updateQuantityInCart = (lineItemId, quantity) => {
    const checkoutId = this.state.checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];

    return this.props.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  };

  removeLineItemInCart = (lineItemId) => {
    const checkoutId = this.state.checkout.id;

    return this.props.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((res) => {
        this.setState({
          checkout: res,
        });
      });
  };

  handleCartClose = () => {
    this.setState({
      isCartOpen: false,
    });
  };

  getAllLink = () => {
    if (this.state.isM) return null;
    return (
      <Link
        to={"/products/" + StaticData.allURL}
        className="block-horz smblock-vert flex-row"
      >
        <span className="bold-18">all</span>
        <span className="bold-18 tiny-block jam jam-chevron-down"></span>
      </Link>
    );
  };

  getShopLinks = () => {
    if (this.state.isM)
      return (
        <div className="flex-vertical align-stretch dropdown">
          <Link to={"/products/" + StaticData.allURL} className="dropdown-link">
            <h2>all.</h2>
          </Link>
          <Link to={"/products/" + StaticData.pinURL} className="dropdown-link">
            <h2>pins.</h2>
          </Link>
          <Link
            to={"/products/" + StaticData.stickerURL}
            className="dropdown-link"
          >
            <h2>stickers.</h2>
          </Link>
          <Link
            to={"/products/" + StaticData.apparelURL}
            className="dropdown-link"
          >
            <h2>apparel.</h2>
          </Link>
        </div>
      );

    return (
      <div className="flex-vertical align-stretch dropdown">
        <Link to={"/products/" + StaticData.pinURL} className="dropdown-link">
          <span className="bold-18">pins</span>
        </Link>
        <Link
          to={"/products/" + StaticData.stickerURL}
          className="dropdown-link"
        >
          <span className="bold-18">stickers</span>
        </Link>
        <Link
          to={"/products/" + StaticData.apparelURL}
          className="dropdown-link"
        >
          <span className="bold-18">apparel</span>
        </Link>
      </div>
    );
  };

  getHome = () => {
    return (
      <Link to="/" className="a-logo">
        <div className="logo" />
      </Link>
    );
  };

  getFooterLinks = () => {
    if (this.state.isM)
      return (
        <div className="flex-vertical relative align-start smblock-vert">
          <Link to="/about" className="block-horz eensypad-vert">
            <span className="bold-14">about</span>
          </Link>
          <Link to="/faq" className="block-horz eensypad-vert">
            <span className="bold-14">faq</span>
          </Link>
          <Link to="/contact" className="block-horz eensypad-vert">
            <span className="bold-14">contact</span>
          </Link>
        </div>
      );

    return (
      <div>
        <Link to="/about" className="block-horz">
          <span className="bold-14">about</span>
        </Link>
        <Link to="/faq" className="block-horz">
          <span className="bold-14">faq</span>
        </Link>
        <Link to="/contact" className="block-horz">
          <span className="bold-14">contact</span>
        </Link>
      </div>
    );
  };

  calculateCartQuantity = () => {
    let count = 0;
    if (this.state.checkout != null) {
      for (let item of this.state.checkout.lineItems) {
        count += item.quantity;
      }
    }
    return count;
  };

  render() {
    let footer,
      appClass,
      { isM } = this.state;

    if (isM === true) {
      footer = (
        <Switch>
          <Route path="/products/:id" render={() => <Footer isM={isM} />} />
          <Route path="/product/:id" render={() => <Footer isM={isM} />} />
        </Switch>
      );
      appClass = "App";
    } else {
      footer = <Footer isM={isM} footerLinks={this.getFooterLinks()} />;
      appClass = "App flex-vertical-stretch";
    }

    // this.printIds();

    let animateNavBar;
    if (this.state.haveLoaded) {
      animateNavBar = false;
    } else {
      animateNavBar = true;
      this.setState({ haveLoaded: true });
    }

    return (
      <div className={appClass}>
        <Helmet
          title="softy."
          meta={[
            { property: "og:type", content: "website" },
            { property: "og:title", content: "softy." },
            {
              property: "og:description",
              content: "merch at 2 extremes: soft and dark.",
            },
            { property: "og:site_name", content: "softy." },

            {
              name: "keywords",
              content:
                "apparel, enamel pins, stickers, grunge, vaporwave, aesthetic, kstyle, cute, streetwear, anime, goth, baddie",
            },

            { name: "twitter:card", content: "summary" },
            { name: "twitter:title", content: "softy." },
            {
              name: "twitter:description",
              content: "at 2 extremes: soft and dark.",
            },
          ]}
        />

        <NavBar
          isM={isM}
          isCartOpen={this.state.isCartOpen}
          openCart={() => this.setState({ isCartOpen: true })}
          isAnimated={animateNavBar}
          allLink={this.getAllLink()}
          shopLinks={this.getShopLinks()}
          home={this.getHome()}
          cartItems={this.calculateCartQuantity()}
          footerLinks={this.getFooterLinks()}
        />

        <Switch>
          {renderRoutes(Routes, {
            isM: isM,
            client: this.props.client,
            addVariantToCart: this.addVariantToCart,
          })}
        </Switch>
        {footer}
        {/* {this.getEmailPopup()} */}
        <ACart
          isM={isM}
          checkout={this.state.checkout}
          show={this.state.isCartOpen}
          handleCartClose={this.handleCartClose}
          updateCartAttributes={this.updateCartAttributes}
          updateQuantityInCart={this.updateQuantityInCart}
          removeLineItemInCart={this.removeLineItemInCart}
        />
      </div>
    );
  }
}

export default App;
