import React, { Component } from "react";
import SquiggleHeader from "../frames/SquiggleHeader";
import Decor from "./Decor";
import axios from "axios";
import Helmet from "react-helmet";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      mailSent: false,
      error: null,
    };
  }

  setVal = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: "https://softy-shop.com/api/contact.php",
      headers: { "content-type": "application/json" },
      data: this.state,
    })
      .then((result) => {
        this.setState({
          mailSent: result.data.sent,
        });
      })
      .catch((error) => this.setState({ error: error.message }));
  };

  render() {
    if (this.state.mailSent === true) {
      return (
        <div className="stretch rel flex-vertical">
          <Decor options={4} />
          <SquiggleHeader
            title="thank you!"
            subtitle="i'll get back to you soon."
          />
        </div>
      );
    }

    let disabled =
      this.state.email === "" ||
      this.state.name === "" ||
      this.state.message === "";

    return (
      <div className="stretch rel flex-vertical">
        <Helmet title="contact." />
        <Decor options={4} />
        <SquiggleHeader
          title="hello,"
          className="block-margin-bottom"
        />
        <label className="tiny-block">
          <span className="med-20 small-block">my name is</span>
          <input
            type="text"
            name="name"
            placeholder="your name"
            onChange={this.setVal}
          />
          <span className="med-20 tiny-block">.</span>
        </label>

        <label>
          <span className="med-20 small-block">my email is</span>
          <input
            type="email"
            name="email"
            placeholder="your email"
            onChange={this.setVal}
          />
          <span className="med-20 tiny-block">.</span>
        </label>

        <textarea
          className="small-block"
          name="message"
          rows="8"
          cols="45"
          wrap="hard"
          placeholder="your message"
          onChange={this.setVal}
        ></textarea>

        <button
          className="block-horz button v2 white"
          type="submit"
          value="leave message"
          onClick={this.onSubmit}
          disabled={disabled}
        >
          submit
        </button>
        {this.state.error && (
          <span className="tiny-block pink book-12">
            {this.state.error}
          </span>
        )}
      </div>
    );
  }
}

export default Contact;
