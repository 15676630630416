import React, { Component } from "react";

class VariantSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		let { options, selected, handleOptionChange } = this.props;
		return (
			<div className="flexRow justifyStart tinyblock-top">
				<p className="option_title">{options.name}</p>
				<div className="slctWrapper">
					<select name={options.name} key={options.name} onChange={handleOptionChange}>
						{options.values.map((valueObj) => {
							return (
								<option value={valueObj.value} key={`${options.name}-${valueObj.value}`} selected={selected(valueObj.value)} disabled={false}>
									{valueObj.value}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		);
	}
}

export default VariantSelector;
