import StaticData from "./StaticData";

export const LoadProductPage = (client, id) => {
  return client.product.fetch(id);
};

export const LoadProducts = (client, id) => {
  switch (id) {
    case StaticData.pinURL:
      return client.collection
        .fetchWithProducts(StaticData.getPinId())
        .then((collection) => collection.products);
    case StaticData.stickerURL:
      return client.collection
        .fetchWithProducts(StaticData.getStickerId())
        .then((collection) => collection.products);
    case StaticData.apparelURL:
      return client.collection
        .fetchWithProducts(StaticData.getApparelId())
        .then((collection) => collection.products);
    default:
    case StaticData.allURL:
      // if total products is more than 50, increase below
      return client.product.fetchAll(50);
  }
};
