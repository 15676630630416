import React, { Component } from "react";

class Decor extends Component {
  render() {
    switch (this.props.options) {
      case 0:
        //about
        if (this.props.isM) {
          return (
            <div className="absDef">
              <div className="light-bg rect-dec1"></div>
              <div className="paper top">
                <div className="paper-grey" />
              </div>
              <div className="lightpink-bg circle-dec5"></div>
            </div>
          );
        }

        return (
          <div className="absDef">
            <div className="light-bg rect-dec1"></div>
            <div className="paper top">
              <div className="paper-grey" />
            </div>
            <div className="pink-border circle-dec1"></div>
            <div className="lightpink-bg circle-dec5"></div>
          </div>
        );

      case 1:
        //home
        return (
          <div className="absDef">
            <div className="paper bottom" />
            <div className="light-bg rect-dec1"></div>
            <div className="pink-border circle-dec1"></div>
            <div className="lightpink-bg circle-dec2"></div>
            <div
              className="blue-rect-dec"
              style={{
                zIndex: 1
              }}
            ></div>
          </div>
        );

      case 2:
        //products
        return (
          <div className="absDef">
            <div className="pink-border circle-dec4"></div>
            <div className="blue-rect-dec v2"></div>
          </div>
        );

      case 3:
        //check
        return (
          <div className="absDef">
            <div
              className="blue-rect-dec"
              style={{
                opacity: 0.5
              }}
            ></div>
            <div
              className="lightpink-bg circle-dec2"
              style={{
                opacity: 0.6
              }}
            ></div>
          </div>
        );

      case 4:
        //contact
        return (
          <div className="absDef">
            <div className="light-bg rect-dec1"></div>
            <div className="pink-border circle-dec1"></div>
            <div className="lightpink-bg circle-dec2"></div>
          </div>
        );

      case 5:
        //misc
        return (
          <div className="absDef">
            <div className="lightpink-bg rect-dec1"></div>
            <div className="pink-border circle-dec1"></div>
            <div className="lightpink-bg circle-dec2"></div>
          </div>
        );

      default:
        return null;
    }
  }
}

export default Decor;
