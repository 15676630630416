import React, { Component } from "react";
/*
id
*/
class ProductImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      title: "",
    };
  }

  componentDidMount() {
    this.props.client.product
      .fetch(this.props.id)
      .then((product) => {
        this.setState({ image: product.images[0], title: product.title });
      })
      .catch((error) => {
        // console.log('ProductImage:' + error.message)
      });
  }

  render() {
    if (this.state.image == null || this.state.title == "") return null;

    return (
      <img
        src={this.state.image.src}
        alt={`${this.state.title} product shot`}
      />
    );
  }
}

export default ProductImage;
