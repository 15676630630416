import React, { Component } from 'react';
import SideBorderedContent from '../frames/SideBorderedContent'
import SquiggleHeader from '../frames/SquiggleHeader'
import Helmet from "react-helmet";


class Calcuator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tees: 0,
      longSleeves: 0,
      wallPapers: false,
      artBook: false
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  render() {
    let { isM } = this.props;
    let { tees, longSleeves, wallPapers, artBook } = this.state;

    let amount = "$0", tier = "(?)";

    if (tees == 0 && longSleeves == 0) {
      if (wallPapers == true && artBook == false) {
        amount = "$5 or more";
        tier = 2;
      }
      else if (wallPapers == false && artBook == true) {
        amount = "$10 or more";
        tier = 3;
      }
      else if (wallPapers == true && artBook == true) {
        amount = "$28 or more";
        tier = 5;
      }
    }
    else if (tees == 1 && longSleeves == 0) {
      if (wallPapers == false && artBook == false) {
        amount = "$22 or more";
        tier = 4;
      }
      else {
        amount = "$28 or more";
        tier = 5;
      }
    }
    else if (tees == 0 && longSleeves == 1) {
      if (wallPapers == false && artBook == false) {
        amount = "$32 or more";
        tier = 5;
      }
      else {
        amount = "$38 or more";
        tier = 5;
      }
    }
    else if (tees > 1 && longSleeves == 0) {
      let pay = 44;
      if (tees > 2) {
        pay += 22 * (tees - 2);
      }
      amount = "$" + pay + " or more";
      tier = 6;
    }
    else if (tees == 0 && longSleeves > 1) {
      let pay = 60;
      if (longSleeves > 2) {
        pay += 32 * (longSleeves - 2);
      }
      amount = "$" + pay + " or more";
      tier = 8;
    }
    else if (tees >= 1 && longSleeves >= 1) {
      let pay = 50;
      if (longSleeves > 1) {
        pay += 32 * (longSleeves - 1);
      }
      if (tees > 1) {
        pay += 22 * (tees - 1);
      }
      amount = "$" + pay + " or more";
      tier = 7;
    }

    let content = <div className="stretch pink-bg rel flex-row flex-wrap">
      <Helmet
        title="calculator."
      />
      <SquiggleHeader
        title='calculator.'
        filter='invert(0.5) sepia(100%) hue-rotate(310deg)'
        color='black'
      />
      <div className={"restrict-content book-12" + (isM === true ? ' block-horz' : '')} style={{
        lineHeight: '200%'
      }}>
        Calculate how much to pay for <a className="darkgrey" href="https://www.kickstarter.com/projects/zephybite/softy-edgy-yet-soft-apparel/">softy's kickstarter</a>.

        <p className="book-16">
          I want:
          <span className="flex-row">

            <input className="tiny-block small-input" type="text" name="tees" value={tees}
              onChange={this.handleChange} /> tees,
              </span>
          <span className="flex-row">
            <input className="tiny-block small-input" type="text" name="longSleeves" value={longSleeves}
              onChange={this.handleChange} /> longsleeves,
          </span>
          <span className="flex-row">
            <input className="checkbox tiny-block" type="checkbox" name="wallPapers" checked={wallPapers}
              onChange={this.handleCheckbox} /> wallpapers, and
                </span>
          <span className="flex-row">
            <input className="checkbox tiny-block" type="checkbox" name="artBook" checked={artBook}
              onChange={this.handleCheckbox} /> artbook.
                </span>
        </p>
        <br />
        <p className="book-16">
          I pay <b className="bold-16">{amount}</b> with <b className="bold-16">tier {tier}</b>.
        </p>
      </div>
    </div>


    return (
      <SideBorderedContent
        isM={this.props.isM}
        content={content}
      />
    );
  }
}

export default Calcuator;
