import React, { Component } from "react";

class LineItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quantity: props.line_item.quantity,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.line_item.quantity != this.props.line_item.quantity && this.props.line_item.quantity != this.state.quantity) {
			this.setQuantity(this.props.line_item.quantity);
		}
	}

	setQuantity = (quantity) => this.setState({ quantity });

	handleQuantityChange = (e) => {
		let quantity = e.target.value;
		this.setQuantity(quantity);
		if (e.target.value == null || e.target.value === "") return;
		this.props.updateQuantityInCart(this.props.line_item.id, quantity);
	};

	render() {
		// return null;
		let { isM, line_item, removeLineItemInCart } = this.props;
		let { quantity } = this.state;
		let size = line_item.variant.selectedOptions.find((option) => option.name == "Size");
		return (
			<li className={"flex-row align-stretch " + (isM === true ? "tinyblock-vert" : "smblock-vert")}>
				<div className="line-item-img">
					{line_item.variant.image ? <img src={line_item.variant.image.src} alt={`${line_item.title} product shot`} /> : null}
				</div>
				<div className="line-item-content">
					<div className="line-item__content-row">
						<span className={isM === true ? "med-14" : "med-20"}>
							{line_item.title}
							{size && ` (${size.value})`}
						</span>
						<button className="line-item__remove" onClick={() => removeLineItemInCart(line_item.id)}>
							<span className="jam jam-close" />
						</button>
					</div>

					<input min="1" type="number" value={quantity} onChange={this.handleQuantityChange}></input>

					{/* <div className="line-item__quantity-container">
            <button className="line-item__quantity-update" onClick={() => this.decrementQuantity(line_item.id)}>-</button>
            <span className="line-item__quantity">{line_item.quantity}</span>
            <button className="line-item__quantity-update" onClick={() => this.incrementQuantity(line_item.id)}>+</button>
          </div> */}
					<span className="line-item__content-row darkgrey smblock-vert line-item-price">
						$ {(line_item.quantity * line_item.variant.price.amount).toFixed(2)}
					</span>
				</div>
			</li>
		);
	}
}

export default LineItem;
